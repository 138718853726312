import request from '@/utils/request'

/**
 * 服务商首页 自营司机在线占比统计接口
 * @returns {*}
 */
export function self_driver_statics (params) {
  return request({
    url: 'common/facilitator/self_driver_statics',
    method: 'get',
    params
  })
}

/**
 * 服务商首页 共享司机在线占比统计接口
 * @returns {*}
 */
export function share_driver_statics (params) {
  return request({
    url: 'common/facilitator/share_driver_statics',
    method: 'get',
    params
  })
}

/**
 * 服务商首页 订单数量统计
 * @returns {*}
 */
export function order_amount_statics (params) {
  return request({
    url: 'common/facilitator/order_amount_statics',
    method: 'get',
    params
  })
}

/**
 * 服务商首页 订单占比统计接口
 * @returns {*}
 */
export function order_proportion_statics (params) {
  return request({
    url: 'common/facilitator/order_proportion_statics',
    method: 'get',
    params
  })
}

/**
 * 服务商首页 以往六月订单统计接口
 * @returns {*}
 */
export function order_past_june_statics (params) {
  return request({
    url: 'common/facilitator/order_past_june_statics',
    method: 'get',
    params
  })
}


/**
 * 首页 平台首页 头部统计
 * @returns {*}
 */
export function getheader_statics (params) {
  return request({
    url: 'common/platform/header_statics',
    method: 'get',
    params
  })
}

/**
 * 首页 平台首页 订单数据总览
 * @returns {*}
 */
export function order_data_statics (params) {
  return request({
    url: 'common/platform/order_data_statics',
    method: 'get',
    params
  })
}

/**
 * 首页 平台首页 企业数据总览
 * @returns {*}
 */
export function firm_data_statics (params) {
  return request({
    url: 'common/platform/firm_data_statics',
    method: 'get',
    params
  })
}

/**
 * 首页 平台首页 订单数量汇总
 * @returns {*}
 */
export function order_count_charts (params) {
  return request({
    url: 'common/platform/order_count_charts',
    method: 'get',
    params
  })
}

/**
 * 首页 平台首页 订单金额汇总
 * @returns {*}
 */
export function order_money_statics (params) {
  return request({
    url: 'common/platform/order_money_statics',
    method: 'get',
    params
  })
}



/**
 * 首页 服务商首页 获取购买会员信息接口
 * @returns {*}
 */
export function member_info (params) {
  return request({
    url: 'common/member_info',
    method: 'get',
    params
  })
}
/**
 * 首页 服务商首页 头部统计
 * @returns {*}
 */
export function facilitatorheader_statics (params) {
  return request({
    url: 'common/facilitator/header_statics',
    method: 'get',
    params
  })
}
/**
 * 首页 服务商首页 订单数据总览
 * @returns {*}
 */
export function facilitatororder_data_statics (params) {
  return request({
    url: 'common/facilitator/order_data_charts',
    method: 'get',
    params
  })
}
/**
 * 首页 服务商首页 企业数据总览
 * @returns {*}
 */
export function facilitatorfirm_data_statics (params) {
  return request({
    url: 'common/facilitator/firm_data_statics',
    method: 'get',
    params
  })
}
/**
 * 首页 平台首页 订单数量汇总
 * @returns {*}
 */
export function facilitatororder_count_charts (params) {
  return request({
    url: 'common/facilitator/order_count_charts',
    method: 'get',
    params
  })
}
/**
 * 首页 平台首页 订单金额汇总
 * @returns {*}
 */
export function facilitatororder_money_statics (params) {
  return request({
    url: 'common/facilitator/order_money_statics',
    method: 'get',
    params
  })
}






/**
 * 首页 渠道首页 头部统计
 * @returns {*}
 */
export function channelheader_statics (params) {
  return request({
    url: 'common/channel/header_statics',
    method: 'get',
    params
  })
}
/**
 * 首页 渠道首页 订单数据总览
 * @returns {*}
 */
export function channelorder_data_charts (params) {
  return request({
    url: 'common/channel/order_data_charts',
    method: 'get',
    params
  })
}


/**
 * 首页 渠道首页 各项服务订单统计
 * @returns {*}
 */
export function channelitems_data_statics (params) {
  return request({
    url: 'common/channel/items_data_statics',
    method: 'get',
    params
  })
}

/**
 * 首页 渠道首页 订单数量汇总
 * @returns {*}
 */
export function channelorder_count_charts (params) {
  return request({
    url: 'common/channel/order_count_charts',
    method: 'get',
    params
  })
}

/**
 * 首页 渠道首页 订单金额汇总
 * @returns {*}
 */
export function channelorder_money_statics (params) {
  return request({
    url: 'common/channel/order_money_statics',
    method: 'get',
    params
  })
}
